<!--  -->
<template>
  <div class="Details">
    <!-- 面包屑导航 -->
    <el-card>
      <el-form :model="form">
        <el-row>
          <el-col :span="8">
            <el-form-item label="单位/班制" :label-width="formLabelWidth">
              <el-cascader
                placeholder="地区/单位/班制"
                :options="optionsAll"
                :props="optionProps"
                v-model="form.word"
                filterable
                clearable
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户名" :label-width="formLabelWidth">
              <el-input v-model="form.username" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="选择岗位" :label-width="formLabelWidth">
              <el-select
                v-model="form.post"
                multiple
                style="width: 100%"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="招聘渠道" :label-width="formLabelWidth">
              <el-radio-group v-model="form.channel" size="small">
                <el-radio-button
                  v-for="item in radiolist2"
                  :label="item.name"
                  :key="item.id"
                ></el-radio-button>
              </el-radio-group>
              <el-input
                v-model="form.channel"
                autocomplete="off"
                v-if="
                  form.channel != '网络' &&
                  form.channel != '内推' &&
                  form.channel != '外协'
                "
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"> </el-col>
          <el-col :span="8"> </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="用工形式" :label-width="formLabelWidth">
              <el-radio-group v-model="form.employment" size="small">
                <el-radio-button
                  v-for="item in options4"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同开始时间" :label-width="formLabelWidth">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.ht_add_time"
                type="datetime"
                placeholder="开始时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="合同结束时间" :label-width="formLabelWidth">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.ht_del_time"
                type="datetime"
                placeholder="开始时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="合同签订年限" :label-width="formLabelWidth">
          <el-radio-group v-model="form.ht_limit" size="small">
            <el-radio-button
              v-for="item in radiolist"
              :label="item.id"
              :key="item.id"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="试用起止时间" :label-width="formLabelWidth">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.add_probation"
            type="datetime"
            placeholder="开始时间"
          >
          </el-date-picker>
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.del_probation"
            type="datetime"
            placeholder="开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="合同签订次数" :label-width="formLabelWidth">
          <el-radio-group v-model="form.ht_c_time" size="small">
            <el-radio-button
              v-for="item in radiolist3"
              :label="item.name"
              :key="item.name"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button
          type="primary"
          class="addbtn"
          @click="addbtn()"
          v-if="this.query.name == '添加员工'"
          >确定添加</el-button
        >
        <el-button type="primary" class="addbtn" @click="editbtn()" v-else
          >确定修改</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import {
  getOptions,
  getRegion,
  getEditHrInfo,
  getHrInfo,
  getHrAdd,
  getSection,
} from "@/api/user";

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      allname: [],
      optionsAll: [],
      optionProps: {
        checkStrictly: false,
        value: "id",
        label: "name",
        children: "sub",
      },
      //添加表单
      form: {
        word: [],
        ht_add_time: "",
        ht_del_time: "",
        ht_limit: "",
        probation: "",
        ht_c_time: "",
        channel: "",
        username: "",
        post: [],
        employment: "",
        add_probation: "",
        del_probation: "",
      },
      //form文本长度
      formLabelWidth: "100px",
      //分类选择options
      options: [],
      cities: [],
      radiolist: [
        {
          id: "一年",
          name: "一年",
        },
        {
          id: "两年",
          name: "两年",
        },
        {
          id: "三年",
          name: "三年",
        },
        {
          id: "无固定",
          name: "无固定",
        },
      ],
      getNav: [],
      type: 0,
      radiolist2: [
        {
          id: "1",
          name: "网络",
        },
        {
          id: "2",
          name: "内推",
        },
        {
          id: "3",
          name: "外协",
        },
        {
          id: "4",
          name: "其他",
        },
      ],
      radiolist3: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
      ],
      work_shift: Number,
      options3: [],
      options4: [],
      options5: [],
      options6: [],
      type2: 0,
      query: null,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async editbtn() {
      console.log(this.form);
      let info = await getEditHrInfo(this.form);
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.$router.push({
          path: "/userlist",
        });
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    editChannel(info) {
      console.log(info);
      if (info == "其他") {
        this.type = 1;
        this.form.work_address = "";
      } else {
        this.type = 0;
      }
    },
    editChannel2(info) {
      if (info == "其他") {
        this.type2 = 1;
        this.form.channel = "";
      } else {
        this.type2 = 0;
      }
    },
    //最终提交
    addbtn() {
      this.setCoupon();
    },
    async setInfo() {
      this.query = this.$route.query;
      if (this.query.id != "add") {
        this.getInfo();
      }
      this.getNav = this.$getNavn("/userlist");
      this.$store.commit("changeRoute", "/userlist");

      let info2 = await getOptions();
      this.options = info2.data.data;
    },
    // 获取详情
    async getInfo() {
      let info = await getHrInfo({
        id: this.query.id,
      });
      this.form = info.data.data;
      console.log(this.form);
    },
    //提交信息
    async setCoupon() {
      let info = await getHrAdd(this.form);
      if (info.data.code == 0) {
        this.$message({
          message: "添加成功",
          type: "success",
        });
        this.$router.push({
          path: "/userlist",
        });
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    async setpOtions() {
      let info = "";
      //用工形式
      info = await getSection();
      this.options4 = info.data.data;
      let info5 = await getRegion();
      this.optionsAll = info5.data.data;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setpOtions();
    this.setInfo();
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped>
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.addbtn {
  width: 300px;
}
</style>